<template>
  <div id="zhBookList">
    <Load @downCallback="downCallback" @upCallback="upCallback"  style="top:5rem">
      <div slot="down">
        <lay-list-zh :dataList="DataList" :isLoading="isLoading" @on-line-click="goInfo"></lay-list-zh>
      </div>
    </Load>
    <div class="source" v-if="['/index_cn'].includes($route.path)" >
      <p v-if="content.name">数据来源：{{content.name}}</p>
      <p v-if="content.telephone">服务热线：<span>{{ content.telephone }}</span></p>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    LayListZh: (resolve) => {require(["@/components/LayListZh.vue"], resolve);},
    Load: (re) => require(["@/components/Load.vue"], re),
  },
  data() {
    return {
      /** 搜索 */
      total: 0,
      showTotal: false,
      search_content: "",
      condition: {
        name: "", //书名
        category_id: "", //书籍分类id
        culture_id: "", //文化分类id
        press_id: "", //出版社id
        isbn: "", //书号
        author: "", //作者名称
        start_time: "", //出版开始时间
        end_time: "", //出版结束时间
        page: 0,
        library_id: this.$route.query.number ? this.$route.query.number : (JSON.parse(localStorage.getItem("theme_color")).library_id ? JSON.parse(localStorage.getItem("theme_color")).library_id : ''), //馆藏id  48内蒙古财经大学图书馆
      },
      selected: "1",
      options: [
        {
          label: "书名",
          value: "1",
        },
        {
          label: "ISBN号",
          value: "2",
        },
        {
          label: "作者",
          value: "3",
        },
        {
          label: "出版社",
          value: "4",
        },
      ],
      dataListUrl: "api/web/v1/book/newlist",
      DataList: [],
      isLoading:false,
    };
  },
  methods: {
    Search() {
      this.showTotal = true;
      this.condition.page = 0;
      this.queryData(false);
    },

    goInfo(item) {
      this.$router.push({
        path: "/zhBookInfo",
        query: {
          id: item.book_id,
        },
      });
    },
    /*****************************************************************
     *
     * 获取列表数据
     *
     *****************************************************************/
    queryData(isload = false) {
      let that = this;
      that.showToastMask(true);
      that.isLoading = false
      that.condition.lang = 'zh'
      that.$http.get("api/web/v1/h5/booklist", {params: that.condition})
          .then((val) => {
            that.total = val.pages.total;
            that.condition.page = val.pages.current_page;
            if (isload) {
              val.data.forEach((item) => {
                that.DataList.push({
                  titlezh:item.name_lang.zh,
                  contentzh:item.content_lang.zh,
                  ...item
                });
              });
              that.showToastMask(false);
            } else {
              that.DataList = val.data.map((item)=>{
                let gg = {
                  titlezh:item.name_lang.zh,
                  contentzh:item.content_lang.zh,
                  ...item
                }
                return gg;
              });
              that.showToastMask(false);
            }
            that.$nextTick(() => {
              window.mescroll.endSuccess(val.data.length);
            });
            that.isLoading = true
          }).catch((err) => {
        that.showToastMask(false);
        that.$nextTick(() => {
          window.mescroll.endSuccess(1);
        });
        that.isLoading = true
      });
    },
    /************************************************************
     *
     * 下拉刷新回调
     *
     ************************************************************/
    downCallback() {
      let that = this;
      that.condition.name = '',//书名
          that.condition.category_id = '',//书籍分类id
          that.condition.culture_id = '',//文化分类id
          that.condition.press_id = '',//出版社id
          that.condition.isbn = '',//书号
          that.condition.author = '',//作者名称
          that.condition.start_time = '',//出版开始时间
          that.condition.end_time = '',//出版结束时间
          that.condition.page = 1;
      that.DataList = [];
      that.queryData(false);
    },

    /************************************************************
     *
     * 上拉加载回调
     *
     ************************************************************/
    upCallback() {
      let that = this;
      that.condition.page = ++that.condition.page;
      that.queryData(true);
    },
  },
  created() {
    this.condition. library_id=this.$route.query.number ? this.$route.query.number : (JSON.parse(localStorage.getItem("theme_color")).library_id ? JSON.parse(localStorage.getItem("theme_color")).library_id : '')
  },
  computed: {
    library_id() {
      let vv = JSON.parse(localStorage.getItem("theme_color")) ? JSON.parse(localStorage.getItem("theme_color")).library_id : null;
      return vv;
    },
    content(){
      let vv = JSON.parse(localStorage.getItem('gcm')) ? JSON.parse(localStorage.getItem('gcm')) : '';
      let gg = this.$newsdata.find(i => {
        return i.library_id == vv
      });
      return gg.content_zh
    },
    // 语言
    lang () {
      return this.$store.getters.lang
    },
  },
  watch: {
    "$store.state.search": {
      // 表示对象中属性变化的处理函数，这个函数只能叫这个名字
      handler(newVal) {
        this.condition = JSON.parse(JSON.stringify(newVal))
        this.condition.page = 1;
        this.condition.type = 1;
        this.condition.library_id = JSON.parse(localStorage.getItem("theme_color")) ? JSON.parse(localStorage.getItem("theme_color")).library_id : null;;
        this.DataList = [];
        this.queryData(false);
      },
      immediate: false,
      deep: true, // 表示开启深度监听
    },
  },
};
</script>

<style lang="less">
#zhBookList {
.source{
  width: 100%;
  height: 78px;
  position: fixed;
  bottom: 0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p:first-child{
    color: #909090;
    font-size: 1.4rem;
    height: 20px;
    line-height: 20px;
  }
  p:last-child{
    color: #909090;
    font-size: 1.3rem;
    height: 20px;
    line-height: 20px;
    span{
      color: #42b983;
    }
  }
}
}
</style>
